<template>
  <div class="site-header">
    <img class="logo" @click="jumpSite" src="@/assets/logo.png" />
    <h1 class="title">柏悦众诚模板展示中心</h1>
  </div>
</template>

<script>
export default {
  methods: {
    jumpSite() {
      window.location.href = "//www.baiyuezhongcheng.com";
    }
  }
};
</script>
<style lang="scss" scoped>
@media (max-width: 600px) {
  .site-header {
    padding: 0 !important;
    .logo {
      display: none !important;
    }
  }
}

.site-header {
  width: 100%;
  height: 50px;
  padding: 0 40px;
  box-sizing: border-box;
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.95);
  .logo {
    display: block;
    height: 50px;
    cursor: pointer;
    position: relative;
    z-index: 501;
  }

  .small-logo {
    display: none;
    height: 50px;
  }

  .title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 50px;
    position: absolute;
    top: 0%;
    left: 0%;
  }
}
</style>
