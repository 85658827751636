<template>
  <div class="list-page">
    <SiteHeader />

    <div class="mainx">
      <p class="title">模板快速建站专家</p>
      <p class="desc">Template Quick Site Expert</p>
    </div>

    <iframe
      id="list"
      ref="iframe"
      src="/list?tid=33"
      frameborder="0"
      :height="iframeHeight"
      @load="loadIframe"
    ></iframe>

    <div class="weixing-show" v-if="openWx">
      <img class="weixing-ma" src="@/assets/qrcode.jpg" />
      <div class="weixing-txt">欢迎咨询</div>
      <div class="weixing-txt">微信扫一扫</div>
      <img
        class="icon-close"
        @click="openWx = false"
        src="@/assets/close_im.png"
        alt=""
      />
    </div>

    <img
      class="icon-open"
      v-else
      @click="openWx = true"
      src="@/assets/open_im.png"
    />

    <SiteFooter />
  </div>
</template>
<script>
import SiteHeader from "@/components/SiteHeader.vue";
import SiteFooter from "@/components/SiteFooter.vue";
export default {
  data() {
    return {
      iframeHeight: 0,
      openWx: true
    };
  },
  mounted() {
    window.addEventListener("resize", this.getIframeHeight);
  },
  methods: {
    loadIframe() {
      window.scrollTo({
        top: 0
      });
      this.getIframeHeight();
    },
    getIframeHeight() {
      setInterval(() => {
        const iframe = this.$refs.iframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        const iframeHeight = iframeDocument.body.scrollHeight;
        this.iframeHeight = iframeHeight + 100;
      }, 300);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getIframeHeight);
  },
  components: {
    SiteHeader,
    SiteFooter
  }
};
</script>
<style lang="scss" scoped>
@media (max-width: 1200px) {
  .list-page {
    padding: 50px 20px !important;
    box-sizing: border-box;
  }
}

.list-page {
  background-color: #f5f5f5;
  padding: 50px 100px;
  box-sizing: border-box;

  .icon-open {
    position: fixed;
    right: 20px;
    top: 70%;
    cursor: pointer;
  }
  .weixing-show {
    width: 100px;
    padding: 10px;
    height: 142px;
    border-radius: 10px;
    border: 1px solid #dddddd;
    position: fixed;
    right: 20px;
    top: 70%;
    background-color: #fff;
    .weixing-ma {
      width: 100%;
    }

    .weixing-txt {
      font-size: 14px;
      text-align: center;
    }

    .icon-close {
      position: absolute;
      top: -16px;
      right: 10px;
      cursor: pointer;
    }
  }
  #list {
    width: 100%;
  }

  .mainx {
    padding: 65px 0 32px 0;
    text-align: center;
    .title {
      color: #000000;
      font-size: 25px;
      line-height: 28px;
      font-weight: 400;
    }

    .desc {
      color: #767676;
      font-size: 15px;
    }
  }
}
</style>
